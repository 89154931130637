import React from 'react'
import { useStaticQuery, graphql, Link } from 'gatsby'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import tw from 'twin.macro'
import styled from '@emotion/styled'
import { ReactSVG } from 'react-svg'
import SEO from '../components/Seo'

const IndexPage = () => {
  const data = useStaticQuery(graphql`
    {
      wp {
        globalSettings {
          markets {
            markets {
              flag {
                localFile {
                  ...FlagImage
                }
              }
              name
              slug
            }
          }
        }
      }
      file(name: { eq: "MH-LOGO-NORDIC-gray-on-white-RGB-01" }) {
        id
        publicURL
      }
    }
  `)

  const PageWrap = styled.div`
    ${tw`flex flex-col mx-auto items-center justify-center p-10`}
    height: 100vh;
    max-width: 1024px;
    font-weight: 300;
    font-family: 'Trebuchet MS', sans-serif;
    color: RGBA(140, 130, 121, 1);
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  `

  const LogoWrap = styled.div`
    ${tw`mb-12`}
    width: 100%;
    max-width: 560px;
    svg {
      width: 100%;
      max-width: 560px;
    }
  `

  const MarketsTitle = styled.div`
    ${tw`mb-6 text-base md:text-lg`}
  `

  const MarketsWrap = styled.div`
    ${tw`flex flex-wrap`}
  `

  const Market = styled.div`
    ${tw`w-1/3 text-center mb-5`}

    a {
      ${tw`block p-2 md:pt-8 md:px-8 text-base md:text-lg`}

      .gatsby-image-wrapper {
        ${tw`rounded-full mb-3 w-16 sm:w-20`}
      }
    }
  `

  const MarketName = styled.div`
    ${tw``}
  `

  return (
    <PageWrap>
      <SEO title="Home" />
      <LogoWrap>
        <ReactSVG src={data.file.publicURL} />
      </LogoWrap>
      <MarketsTitle>Choose market</MarketsTitle>
      <MarketsWrap>
        {data?.wp.globalSettings.markets.markets.map(
          ({ flag, name, slug }, index) => {
            const imageGatsbyData = getImage(
              flag?.localFile.childImageSharp.gatsbyImageData
            )

            return (
              <Market key={index}>
                <Link to={`/${slug}/`}>
                  <GatsbyImage image={imageGatsbyData} alt={name} />
                  <MarketName>{name}</MarketName>
                </Link>
              </Market>
            )
          }
        )}
      </MarketsWrap>
    </PageWrap>
  )
}

export default IndexPage
